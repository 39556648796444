import { Component, Mixins } from 'vue-property-decorator'
import { AppConfig } from '~/shared'

import { ProvideReactive } from '@/vue-extensions'
import { INJECTIONS } from '@/injections'
import HandlesErrors from '@/providers/HandlesErrors'
import { get } from '@/api/config'
import { Status } from '@/constants'

@Component({})
export default class ConfigProvider extends Mixins(HandlesErrors) {
	@ProvideReactive(INJECTIONS.CONFIG.DATA) data: AppConfig = null
	@ProvideReactive(INJECTIONS.CONFIG.STATUS) status: Status = Status.ready

	created() {
		this.loadConfig()
	}

	async loadConfig() {
		this.status = Status.pending
		try {
			this.data = await get()
			console.info('Hi! You are using ArchiGraph.Mir', {
				commmit: this.data.commit,
			})
			this.$emit('loadConfig', this.data)
			this.status = Status.success
		} catch (e) {
			this.handleError(e)
			this.status = Status.failure
			throw e
		}
	}

	render() {
		return this.$slots.default
	}
}
