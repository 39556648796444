<template>
	<div id="app" :class="appClass">
		<EventBusProvider>
			<ErrorHandler>
				<ConfigProvider @loadConfig="onLoadConfig">
					<AuthProvider>
						<SettingsProvider>
							<router-view />
						</SettingsProvider>
					</AuthProvider>
				</ConfigProvider>
			</ErrorHandler>
		</EventBusProvider>
	</div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

import { Colorscheme, Language } from '@/constants'
import AuthProvider from '@/providers/AuthProvider'
import NotificationContainer from '@/components/Common/Toast/NotificationContainer.vue'
import ErrorHandler from '@/providers/ErrorHandler'
import { Provide, ProvideReactive } from '@/vue-extensions'
import { INJECTIONS } from '@/injections'
import ConfigProvider from '@/providers/ConfigProvider'
import SettingsProvider from '@/providers/SettingsProvider'
import { EventBusProvider } from '@/core/events/event-bus.provider'
import { AppConfig } from '~/shared'
import { apiClient } from '@/api/client'

@Component({
	components: {
		ConfigProvider,
		ErrorHandler,
		NotificationContainer,
		AuthProvider,
		EventBusProvider,
		SettingsProvider,
	},
})
export default class App extends Vue {
	Colorcheme = Colorscheme
	@ProvideReactive(INJECTIONS.COLORSCHEME.CURRENT)
	currentColorscheme: Colorscheme = Colorscheme.default

	created() {
		const colorscheme = window.localStorage.getItem('agmir_colorscheme')
		if (colorscheme) {
			this.currentColorscheme = JSON.parse(colorscheme).value
		}
		const uiLanguage = window.localStorage.getItem('agmir_uiLanguage')
		if (uiLanguage) {
			this.uiLanguage = JSON.parse(uiLanguage).value
		}
	}

	get appClass() {
		return {
			[this.Colorcheme.default]: '',
			[this.Colorcheme.alternative]: 'b-wrapper__blue',
			[this.Colorcheme.alternative2]: 'b-wrapper__alternative2',
		}[this.currentColorscheme]
	}

	@Provide(INJECTIONS.COLORSCHEME.SET) setColorscheme(colorscheme: Colorscheme) {
		this.currentColorscheme = colorscheme
		window.localStorage.setItem('agmir_colorscheme', JSON.stringify({ value: colorscheme }))
	}

	@ProvideReactive(INJECTIONS.UI_LANGUAGE.CURRENT) uiLanguage: Language = Language.ru
	@Provide(INJECTIONS.UI_LANGUAGE.SET) setUiLanguage(lang: Language) {
		this.uiLanguage = lang
		window.localStorage.setItem('agmir_uiLanguage', JSON.stringify({ value: lang }))
	}

	onLoadConfig(config: AppConfig) {
		if (!window.localStorage.getItem('agmir_uiLanguage')) {
			this.uiLanguage = config.defaultUiLanguage as Language
		}
		window.localStorage.setItem('agmir_SUZ_FRONT_URL', config.SUZ_FRONT_URL)
		if (config.IS_SIBUR) {
			window.localStorage.setItem('agmir_SIBUR', '1')
		} else {
			window.localStorage.removeItem('agmir_SIBUR')
		}
	}

	@Watch('uiLanguage', { immediate: true })
	OnUiLanguageChange(val: Language) {
		apiClient.patch('uiLang/' + val)
	}
}
</script>

<style lang="scss">
@import 'assets/styles/main.css';
@import 'assets/styles/media.css';
@import 'assets/styles/blue.css';
@import 'assets/styles/custom.css';
@import 'assets/styles/transitions.css';
@import 'assets/styles/table.scss';
@import '../node_modules/vue-popperjs/dist/vue-popper.css';

#app {
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&.b-wrapper__alternative2 {
		filter: hue-rotate(310deg) grayscale(12%) contrast(108%);
	}
}

a.ref {
	color: #ed7407;
	text-decoration: underline;
	font-weight: 500;

	&:not(:last-child) {
		margin-right: 11px;
	}
}
</style>
