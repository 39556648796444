import { GetterTree, MutationTree, ActionTree } from 'vuex'

class State {
	notifications: { id: string; payload?: any }[] = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
	add(state, payload: { id: string; payload?: any } | string) {
		const v = typeof payload === 'string' ? { id: payload } : payload
		state.notifications.push(v)
	},
	clear(state) {
		state.notifications = []
	},
}

const actions: ActionTree<State, any> = {}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
