import { ARCHIGRAPH, OWL, RDFS } from '~/shared'
import { NavigationLink, TranslatableLabel } from '@/types'
import { PropOptions } from 'vue'
import { comparators } from '@/core/filtering/comparators'
import { ExportType } from '~/shared/exchange'

export enum Language {
	ru = 'ru',
	en = 'en',
}

export enum Colorscheme {
	default,
	alternative,
	alternative2,
}

export enum Status {
	ready = 'ready',
	pending = 'pending',
	success = 'success',
	failure = 'failure',
}

export enum InputType {
	text = 'text',
	textArea = 'textArea',
	number = 'number',
	select = 'select',
	checkBox = 'checkBox',
	autoComplete = 'autoComplete',
	date = 'date',
	dateTime = 'dateTime',
}

export const API_BASE_URL = import.meta.env.VITE_API_URL || '/api'

const isApiBaseUrlAbsolute =
	API_BASE_URL.indexOf('http://') === 0 || API_BASE_URL.indexOf('https://') === 0

const replaceHttpToWs = (orig: string) => orig.replace('http', 'ws')
export const SOCKET_BASE_URL =
	replaceHttpToWs(isApiBaseUrlAbsolute ? API_BASE_URL : window.location.origin + API_BASE_URL) +
	'/ws'

export type AttributeId =
	| 'id'
	| 'name'
	| RDFS.subClassOf
	| RDFS.domain
	| RDFS.range
	| 'types'
	| ARCHIGRAPH.archive
	| OWL.minCardinality
	| OWL.maxCardinality
	| RDFS.comment
	| RDFS.isDefinedBy
	| RDFS.label

type AttributeLabelsDict = {
	[P in AttributeId]: TranslatableLabel
}
export const ATTRIBUTE_LABELS: AttributeLabelsDict = {
	id: {
		[Language.ru]: 'Id',
		[Language.en]: 'Id',
	},
	name: {
		[Language.ru]: 'Наименование',
		[Language.en]: 'Name',
	},
	[RDFS.label]: {
		[Language.ru]: 'Наименование',
		[Language.en]: 'Name',
	},
	[ARCHIGRAPH.archive]: {
		[Language.ru]: 'Удаленный',
		[Language.en]: 'Deleted',
	},
	[RDFS.subClassOf]: {
		[Language.ru]: 'Родительский класс',
		[Language.en]: 'Parent class',
	},
	[RDFS.domain]: {
		[Language.ru]: 'Область применения',
		[Language.en]: 'Domain',
	},
	[RDFS.range]: {
		[Language.ru]: 'Диапазон значений',
		[Language.en]: 'Range',
	},
	[OWL.minCardinality]: {
		[Language.ru]: 'Минимальное количество',
		[Language.en]: 'Minimal cardinality',
	},
	[OWL.maxCardinality]: {
		[Language.ru]: 'Максимальное количество',
		[Language.en]: 'Maximal cardinality',
	},
	[RDFS.comment]: {
		[Language.ru]: 'Описание',
		[Language.en]: 'Description',
	},
	[RDFS.isDefinedBy]: {
		[Language.ru]: 'Определено в',
		[Language.en]: 'Is defined by',
	},
	types: {
		[Language.ru]: 'Класс',
		[Language.en]: 'Class',
	},
}

export const COMPARATOR_LABELS: {
	[P in keyof typeof comparators]: TranslatableLabel
} = {
	dateWithinTimespan: {
		[Language.ru]: 'Дата входит в отрезок времени',
		[Language.en]: 'Date is within timespan',
	},
	dateTimeWithinTimespan: {
		[Language.ru]: 'Дата и время входит в отрезок времени',
		[Language.en]: 'Datetime is within timespan',
	},
	stringEquals: {
		[Language.ru]: 'Равно',
		[Language.en]: 'Equals',
	},
	stringNotEquals: {
		[Language.ru]: 'Не равно',
		[Language.en]: 'Not equals',
	},
	stringContains: {
		[Language.ru]: 'Строка содержит',
		[Language.en]: 'String contains',
	},
	stringBeginningContains: {
		[Language.ru]: 'Начало строки содержит',
		[Language.en]: 'Beginning of string contains',
	},
	stringEqualsStrict: {
		[Language.ru]: 'Строка равна (строго)',
		[Language.en]: 'String equals (strictly)',
	},
	stringRegEx: {
		[Language.ru]: 'Регулярное выражение',
		[Language.en]: 'Regular expression',
	},
	numberEquals: {
		[Language.ru]: 'Равно',
		[Language.en]: 'Equals',
	},
	numberNotEquals: {
		[Language.ru]: 'Не равно',
		[Language.en]: 'Not equals',
	},
	numberMoreThan: {
		[Language.ru]: 'Число больше чем',
		[Language.en]: 'Number more than',
	},
	numberMoreOrEqualsThan: {
		[Language.ru]: 'Число больше или равно',
		[Language.en]: 'Number more or equals',
	},
	numberLessThan: {
		[Language.ru]: 'Число меньше чем',
		[Language.en]: 'Number less than',
	},
	numberLessOrEqualsThan: {
		[Language.ru]: 'Число меньше или равно',
		[Language.en]: 'Number less or equals',
	},
	refEquals: {
		[Language.ru]: 'Равно',
		[Language.en]: 'Equals',
	},
	refNotEquals: {
		[Language.ru]: 'Не равно',
		[Language.en]: 'Not equals',
	},
	refArrayContains: {
		[Language.ru]: 'Одно из значений равно',
		[Language.en]: 'One from the values is equal to',
	},
	refArrayNotContains: {
		[Language.ru]: 'Ни одно из значений не равно',
		[Language.en]: 'None of the values is equal to ',
	},
	booleanEquals: {
		[Language.ru]: 'Равно',
		[Language.en]: 'Equals',
	},
	booleanNotEquals: {
		[Language.ru]: 'Не равно',
		[Language.en]: 'Not equals',
	},
	referenceNameContains: {
		[Language.ru]: 'Наименование содержит',
		[Language.en]: 'Name contains',
	},
	anyExists: {
		[Language.ru]: 'Существует',
		[Language.en]: 'Exists',
	},
	anyNotExists: {
		[Language.ru]: 'Не существует',
		[Language.en]: 'Not exists',
	},
	subrequest: {
		[Language.ru]: 'Использовать подзапрос',
		[Language.en]: 'User subrequest',
	},
}

export enum AuthState {
	idle = 'idle',
	checkupPending = 'checkupPending',
	authenticated = 'authenticated',
	failed = 'failed',
	loggedOut = 'loggedOut',
}

export const commonProps: Record<string, PropOptions<any>> = {
	objectType: {
		type: String,
		required: true,
		validator: (type) => {
			return [OWL.Class, OWL.NamedIndividual, OWL.DatatypeProperty, OWL.ObjectProperty].includes(
				type as OWL
			)
		},
	},
	isNew: {
		type: Boolean,
		required: true,
	},
	changed: {
		type: Boolean,
		required: true,
	},
}

export const exportTypeLabels: {
	[P in Exclude<ExportType, 'reportРабота' | 'reportЗапчасть'>]: TranslatableLabel
} = {
	classModel: {
		[Language.ru]: 'Модель класса',
		[Language.en]: 'Class model',
	},
	classModelWithChildren: {
		[Language.ru]: 'Модель класса и его подклассов',
		[Language.en]: 'Class and its subclasses model',
	},
	individualsModel: {
		[Language.ru]: 'Модель объектов класса и его подклассов',
		[Language.en]: 'Individuals of the class and its subclasses model',
	},
	attributes: {
		[Language.ru]: 'Атрибуты, применимые непосредственно к объектам класса',
		[Language.en]: 'Attributes applicable to the class individuals (without inherited)',
	},
	attributesWithInherited: {
		[Language.ru]: 'Все атрибуты, применимые к объектам класса',
		[Language.en]: 'All the attributes applicable to the class individuals',
	},
	individuals: {
		[Language.ru]: 'Объекты класса',
		[Language.en]: 'Class individuals',
	},
	individualsWithChildren: {
		[Language.ru]: 'Объекты класса и его подклассов',
		[Language.en]: 'Class and its subclasses individuals',
	},
	classesDOCX: {
		[Language.ru]: 'Выгрузка классов',
		[Language.en]: 'Classes download',
	},
	attributesDOCX: {
		[Language.ru]: 'Выгрузка атрибутов',
		[Language.en]: 'Attributes download',
	},
	classesAttributesDOCX: {
		[Language.ru]: 'Выгрузка классов и атрибутов',
		[Language.en]: 'Classes and attributes download',
	},
	history: {
		[Language.ru]: 'История объектов класса',
		[Language.en]: 'History of class individuals',
	},
	historyWithChildren: {
		[Language.ru]: 'История объектов класса и его подклассов',
		[Language.en]: 'History of class individuals and its subclasses individuals',
	},
}

export const commonNavigationLinks: NavigationLink[] = [
	{
		ref: '/settings/',
		label: {
			i18n: 'settings',
		},
	},
	{
		ref: '/about/',
		label: {
			i18n: 'aboutTheApp',
		},
	},
]

export const addExchangeModelLink = (links: NavigationLink[], endpoint: string) => {
	if (window.localStorage.getItem('agmir_SIBUR')) {
		links.splice(3, 0, {
			ref: `/endpoint/${endpoint}/exchange-model`,
			label: {
				[Language.ru]: 'Импорт/экспорт модели данных',
				[Language.en]: 'Import/export model data',
			},
		})
	}
}
