import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {apiClient} from '@/api/client'
import {ShallowClass} from '~/shared'
import {getList as getEndpoints} from "@/api/endpoints";

export type EndpointMetaClassesParams = {
  endpointId: string
  lang?: string
}

export type MetaClassInfo = {
  id: string,
  name: string
}

export type UpdateParam = {
  list: MetaClassInfo[],
  endpointParam: EndpointMetaClassesParams
}

class State {
  showMeta: string[] = []
  allMetaClasses: MetaClassInfo[] = []
}

const getLang = () => {
  let lang = localStorage.getItem('agmir_agmir:contentLanguage')
  if (lang) {
    lang = JSON.parse(lang).id
  }
  return lang
}

const getEndpoint = async () => {
  const endpointList = await getEndpoints()
  const currentEndpoint =
    endpointList.find((e) => e.id === localStorage.getItem('agmir_endpoint')) ||
    endpointList[0]
  return currentEndpoint.id
}

let lang
let endpointId

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setAllMetaClasses(state, payload: MetaClassInfo[]) {
    state.allMetaClasses = payload
  },
  setShowMeta(state, payload: MetaClassInfo[]) {
    state.showMeta = payload
  },
}

const actions: ActionTree<State, any> = {
  async update(context, payload: UpdateParam) {
    const data = (
      await apiClient.post(
        `endpoints/${payload.endpointParam.endpointId}/show_meta_list?language=${payload.endpointParam.lang}`,
        payload.list
      )
    ).data
    context.commit('setShowMeta', data)
  },

  async init(context, payload?: string) {
    lang = getLang()
    endpointId = payload ? payload : await getEndpoint()
    context.commit(
      'setShowMeta',
      (await apiClient.get(`endpoints/${endpointId}/show_meta_list?language=${lang}`))
        .data
    )
    context.commit(
      'setAllMetaClasses',
      (await apiClient.get(`endpoints/${endpointId}/meta_list?language=${lang}`))
        .data
    )
  },
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
