import ClassDoesNotExistsError from './ClassDoesNotExistsError'
import ObjectDoesNotExistError from './ObjectDoesNotExist'
import AuthInvalidCredentialsError from './AuthInvalidCredentialsError'
import NotAuthenticatedError from './NotAuthenticatedError'
import CacheBuildError from './CacheBuildError'
import UnknownServerError from './UnknownServerError'
import FeatureNotSupportedError from './feature-not-supported-error'
import AttributeIsNotApplicableForClassError from './AttributeIsNotApplicableForClassError'
import ClassAlreadyExistsError from './class-already-exists-error'
import AttributeAlreadyExistsError from './attribute-already-exists-error'
import ProposedToConfirmError from './proposed-to-confirm-error'
import MdmError from './mdm-error'
import BackingServiceUnavailableError from './backing-service-unavailable-error'
import WrongImportHeadersError from './wrong-import-headers-error'
import UndefinedMdmError from './undefined-mdm-error'
import MdmTimeoutError from './mdm-timeout-error'
import NoPrefixesError from './no-prefixes.error'

export const errorTypes = {
	ClassDoesNotExistsError,
	ObjectDoesNotExistError,
	AuthInvalidCredentialsError,
	NotAuthenticatedError,
	CacheBuildError,
	UnknownServerError,
	FeatureNotSupportedError,
	AttributeIsNotApplicableForClassError,
	ClassAlreadyExistsError,
	AttributeAlreadyExistsError,
	ProposedToConfirmError,
	MdmError,
	BackingServiceUnavailableError,
	WrongImportHeadersError,
	UndefinedMdmError,
	MdmTimeoutError,
	NoPrefixesError,
}
