export const INJECTIONS = {
	CONFIG: {
		DATA: 'configData',
		STATUS: 'configStatus',
	},
	timezone: {
		current: 'currentTimezone',
	},
	COLORSCHEME: {
		CURRENT: 'colorschemeCurrent',
		SET: 'colorschemeSet',
	},
	AUTH: {
		STATE: 'authState',
		USER: 'authUser',
		LOGIN: 'authLogin',
		LOGOUT: 'authLogout',
	},
	settings: {
		state: 'settingsState',
		data: 'settingsData',
		patch: 'patchSettings',
	},
	ENDPOINT: {
		LIST: 'endpointList',
		CURRENT: 'endpointCurrent',
		SELECT: 'endpointSelect',
		PENDING: 'endpointPending',
	},
	UI_LANGUAGE: {
		CURRENT: 'uiLanguageCurrent',
		SET: 'uiLanguageSet',
	},
	LANGUAGE: {
		LIST: 'languageList',
		CURRENT: 'languageCurrent',
		DEFAULT: 'languageDefault',
		SELECT: 'languageSelect',
		PENDING: 'languagePending',
	},
	MODEL: {
		PREFIXES: 'modelPrefixes',
		DEFAULT_PREFIX: 'modelDefaultPrefix',
		CLASSES: 'modelClasses',
		ROOT: 'modelRoot',
		PENDING: 'modelPEnding',
		BUILTINS: 'modelBuiltins',
		RESOLVER: 'modelResolver',
		ADD_CLASS: 'modelAddClass',
		PATCH_CLASS: 'modelPatchClass',
		REMOVE_CASS: 'modelRemoveClass',
		BUILD_CLASS: 'modelBuildClass',
		BUILD_ATTRIBUTE: 'modelBuildAttribute',
		BASE_ACCESS: 'modelBaseAccess',
	},
	CLASS: {
		ID: 'classId',
		CURRENT: 'classCurrent',
		attributes: 'classAttributes',
		references: 'classReferences',
		SELECT: 'classSelect',
		PENDING: 'classPending',
		PATCH: 'classPatch',
		ADD_ATTRIBUTE: 'classAddAttribute',
		PATCH_ATTRIBUTE: 'classPatchAttribute',
		REMOVE_ATTRIBUTE: 'classRemoveAttribute',
		addReference: 'class_addReference',
		patchReference: 'class_patchReference',
		removeReference: 'class_removeReference',
		EVENTS: 'classEvents',
	},
	INDIVIDUALS: {
		LIST: 'individualsList',
		SCHEMA: 'individualsSchema',
		TOTAL_COUNT: 'individualsTotalCount',
		PENDING: 'individualsPending',
		SET_QUERY: 'individualsSetQuery',
		QUERY: 'individualsQuery',
		ADD: 'individualsAdd',
		PATCH: 'individualsPatch',
		REMOVE: 'individualsRemove',
		REMOVE_GROUP: 'individualsRemoveGroup',
	},
	QUERY_STORAGE: 'queryStorage',
	SELECTED_CLASS_STORAGE: 'selectedClassStorage',
	navigation: {
		goTo: 'navigationGoTo',
		pending: 'navigationPending',
	},
}
