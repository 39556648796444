import Vue from 'vue'
import VueRouter from 'vue-router'

import workspaceRoutes from '@/views/Workspace/routes'
import { demoRoutes } from '@/views/demos/route'

Vue.use(VueRouter)

const routes = [
	...workspaceRoutes,
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/Login.vue'),
	},
	{
		path: '/',
		redirect: '/endpoint',
	},
	{
		path: '/',
		component: () => import('@/views/Authenticated.vue'),
		children: [
			{
				path: '/settings/',
				component: () => import('@/views/Settings.vue'),
			},
			{
				path: '/about/',
				component: () => import('@/views/About.vue'),
			},
		],
	},
	...demoRoutes,
]

const router = new VueRouter({
	routes,
})

export default router
